/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */

export class TranslationService {
	/**
	 * Default language
	 *
	 * @private
	 */
	private defaultLanguage = 'en';

	/**
	 * Dictionary with translations
	 *
	 * @private
	 */
	private messages = {};

	/**
	 * Method to load dictionary with language constants
	 *
	 * @param filename name of the file with language constants
	 * @param languagePackage language
	 * @param loadDefault flag to indicate to load file with default language as a fallback
	 */
	load(filename: string, languagePackage: string, loadDefault = true) {
		return new Promise(resolve => {
			const url =
				languagePackage === this.defaultLanguage
					? `/messages/${filename}.json`
					: `/messages/${filename}_${languagePackage}.json`;

			fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then(response => {
					// if we received a successful result - try to parse
					if (response.status >= 200 && response.status < 400) {
						return response.json().catch(error => {
							// if it's not possible to parse a response - try to load a default
							if (loadDefault) {
								return this.load(filename, this.defaultLanguage, false);
							} else {
								throw new Error(error.message);
							}
						});
					} else {
						if (loadDefault) {
							return this.load(filename, this.defaultLanguage, false);
						} else {
							resolve({});
						}
					}
				})
				.then(json => resolve(json))
				.catch(() => resolve({}));
		}).then(data => {
			this.messages = { ...this.messages, ...(data as object) };
			return data;
		});
	}

	/**
	 * Method that apply replacement all matched variables in input value with translations
	 * Example: <h2>{{ YOUR_SESSION_HAS_EXPIRED | translate }}</h2>
	 *
	 * @param {string} inputValue
	 */
	applyTranslations(inputValue: string): string {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const service = this;
		return inputValue.replace(/\{\{\s?'((\w+\.?)+)'\s?\|\s?(translate)\s?\}\}/g, function (match, p1) {
			let dictionary = service.messages;
			// split by '.' to support language constants with nesting
			// for example 'SLPT.MESSAGE'
			const chunks = p1.split('.');
			for (let i = 0; i < chunks.length; i++) {
				// if there is no such element - return matched element
				if (!dictionary[chunks[i]]) {
					return p1;
				}
				if (i === chunks.length - 1) {
					// matched element is found. apply additionally filter to remove all tags
					return dictionary[chunks[i]];
				}
				dictionary = dictionary[chunks[i]];
			}
			// if there is no such element - return matched element
			return p1;
		});
	}
}
