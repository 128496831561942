import {
	AppShellState,
	AppShellStateProvider,
	Message,
	MessagePayload,
	OnMessageFn,
	Topic
} from './app-shell-state.model';
import { v4 as uuid } from 'uuid';

// this id can be any valid string. For now, it's generated
// as a uuid
type ID = string;

export class AppShellStateService implements AppShellStateProvider {
	// Keep track of all subscription listeners with easy lookup by id.
	private subscriberOnMsg: Record<ID, OnMessageFn> = {};
	// Keep track of the topic for each subscription id for easier cleanup.
	private subscriberTopics: Record<ID, Topic> = {};
	// Keep track of all topics and subscriber ids for each topic.
	private topics: Record<Topic, ID[]> | Record<string, never> = {};

	private state: AppShellState;

	constructor() {
		this.state = {
			currentUrl: '',
			isNavbarVisible: true,
			heading: ''
		};
	}

	/**
	 * Subscribe to messages being published in the given topic.
	 * @param topic Name of the channel/topic where messages are published.
	 * @param onMessage Function called whenever new messages on the topic are published.
	 * @returns ID of this subscription.
	 */
	public async on({ topic, onMessage }: { topic: Topic; onMessage: OnMessageFn }): Promise<Readonly<ID>> {
		// we could validate the topics here
		// if (typeof topic !== "string") throw new Error("Topic must be a string.");
		const subID = uuid();
		if (!(topic in this.topics)) {
			// New topic
			this.topics[topic] = [subID];
		} else {
			// Topic exists
			this.topics[topic].push(subID);
		}

		this.subscriberOnMsg[subID] = onMessage;
		this.subscriberTopics[subID] = topic;
		return subID;
	}

	// We can restrict the messages types
	// by using a schema validation within the publish() method if we wanted to.
	// Note: If your micro frontend architecture relies on iframes for rendering micro apps from different
	// sources you can use the BroadcastChannel API as a way to pass messages from the event bus
	// to child applications.
	/**
	 * Publish messages on a topic for all subscribers to receive.
	 * @param topic The topic where the message is sent.
	 * @param message The message to send. Only object format is supported.
	 */
	public async emit({ topic, payload }: Message) {
		await this.set(payload);

		if (topic in this.topics) {
			const subIDs = this.topics[topic];
			subIDs.forEach(id => {
				if (id in this.subscriberOnMsg) {
					this.subscriberOnMsg[id](payload);
				}
			});
		}
	}

	/**
	 * Unsusbscribe for a given subscription id.
	 * @param id Subscription id
	 */
	public async off({ subscriptionID, topic }): Promise<void> {
		if (subscriptionID in this.subscriberOnMsg && subscriptionID in this.subscriberTopics) {
			if (topic !== this.subscriberTopics[subscriptionID]) {
				throw new Error(`Topic ${topic} does not match subscription ID`);
			}
			delete this.subscriberOnMsg[subscriptionID];
			// Cleanup topics
			if (topic && topic in this.topics) {
				const idx = this.topics[topic].findIndex(tID => tID === subscriptionID);
				if (idx > -1) {
					this.topics[topic].splice(idx, 1);
				}
				// If there are no more listeners clean up the topic as well
				if (this.topics[topic].length === 0) {
					delete this.topics[topic];
				}
			}
			// Delete the topic for this id
			delete this.subscriberTopics[subscriptionID];
		}
	}

	/**
	 * Gets the current state of the app shell.
	 * @returns {Promise<AppShellState>} A promise that resolves with the current state of the app shell.
	 */
	public async get(): Promise<AppShellState> {
		return this.state;
	}

	/**
	 * Patches the state of the app shell
	 * @param newState Merged with the existing state.
	 */
	public async set(newState: MessagePayload) {
		this.state = { ...this.state, ...newState };
	}
}
