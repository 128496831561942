/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */

export enum EventType {
	LAYER_ACTIVATE = 'layer_activate',
	LAYER_DEACTIVATE = 'layer_deactivate'
}

export type Callback = (input: object) => Promise<object>;

interface ListenerBaseParams {
	eventType: EventType;
	callback: Callback;
}

export interface OnBroadcastParams extends ListenerBaseParams {
	eventId?: string;
}

export interface OnUnicastParams extends ListenerBaseParams {
	eventId: string;
	mfeName: string;
}
export interface BroadcastParams {
	input: object;
	eventId?: string;
	eventType: EventType;
}

export interface UnicastParams extends BroadcastParams {
	mfeName: string;
}

export interface AppShellEventBusProvider {
	broadcast: ({ eventType, eventId, input }: BroadcastParams) => Promise<void>;
	unicast: ({ mfeName, eventType, eventId, input }: UnicastParams) => Promise<object>;
	onBroadcast: ({ eventType, eventId, callback }: OnBroadcastParams) => Promise<void>;
	onUnicast: ({ mfeName, eventType, eventId, callback }: OnUnicastParams) => Promise<void>;
}
