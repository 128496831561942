/*
 * Copyright (C) 2021 SailPoint Technologies, Inc. All rights reserved.
 */
import { Topic } from './app-shell-state.model';
import {
	APP_SHELL_SERVICE_NAME,
	LaunchDarklyContextData,
	MfeContextData,
	MfeProps,
	PendoContextData
} from './app-shell.model';
import { AppShellService } from './app-shell.service';
import { MfeAccessToken } from './auth-credential.model';
import { AuthCredentialsService } from './auth-credential.service';
import { buildAppRoutesHtml } from './engine-functions';
import ImportMapOverridesService from './import-map-overrides/import-map-overrides.service';
import { LegacyBrandingService } from './legacy-branding/legacy-branding.service';
import { LoadTimeMetricsService } from './metrics/load-time-metrics-service';
import { MFE_INFO_NAME, MfeInfo } from './mfe-info.model';
import { RegisterConfig } from './mfe-register.model';
import { PageVisibilityService } from './page-visibility.service';
import { includePath, joinPath, trimSlashes } from './path-functions';
import { PendoService } from './pendo-service';
import './polyfills';
import { TLSData } from './tls.model';
import { TranslationService } from './translation.service';
import { createSessionExpiredPage } from './views/session-expired-page/session-expired-page';
import { ActivityFn, RegisterApplicationConfig, registerApplication, start } from 'single-spa';
import { WithLoadFunction, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import 'systemjs';

const applications: (RegisterApplicationConfig<MfeProps> & WithLoadFunction)[] = [];

/* global System */

const startTime = performance.now();
const pageVisibilityService = new PageVisibilityService();

// Get the credentials used to authenticate API calls by MFEs.
const credentialElement = document.getElementById('mfe-credential-json');
const mfeAccessToken: MfeAccessToken = credentialElement
	? (JSON.parse(credentialElement.textContent) as MfeAccessToken)
	: null;

credentialElement.remove();

// Get the context data that applies to all MFEs.
const contextElement = document.getElementById('mfe-context-json');
const mfeContextData: MfeContextData = contextElement
	? (JSON.parse(contextElement.textContent) as MfeContextData)
	: null;

contextElement.remove();

// Create the credential service. This will poll for a refreshed access token.
// This should be there only when user is authenticated.
const authCredentialsService = mfeAccessToken
	? new AuthCredentialsService(
			mfeAccessToken.accessToken,
			mfeContextData.authContext.csrfToken,
			mfeAccessToken.refreshInterval,
			mfeContextData.authContext.refreshUrl,
			mfeContextData.authContext.logoutUrl,
			pageVisibilityService
	  )
	: null; // in case of unauthenticated app shells

// Get Launchdarkly client side id
const ldClientSideDataElement = document.getElementById('ld-context-json');
const ldClientSideData: LaunchDarklyContextData = ldClientSideDataElement
	? (JSON.parse(ldClientSideDataElement.textContent) as LaunchDarklyContextData)
	: null;
ldClientSideDataElement.remove();

// Get the TLS
const tlsElement = document.getElementById('tls-json');
const tlsData: TLSData = tlsElement ? (JSON.parse(tlsElement.textContent) as TLSData) : null;
tlsElement.remove();

const urlDataElement = document.getElementById('urls-json');
const urlData = urlDataElement ? JSON.parse(urlDataElement.textContent) : null;
urlDataElement.remove();

// Create the app shell service. This will be provided to each MFE. Add the credential service as a
// dependency, to ensure that the app shell service always has a valid access token.
const appShellService = new AppShellService({
	authCredentialsService,
	tlsData,
	urlData,
	data: mfeContextData,
	ldContext: ldClientSideData
});

const apiUrl = mfeContextData.authContext.apiUrl.idn;

// we have to use the synchronous data and not the appshell wrapper because if
// we used the async API, we would create a race condition when overriding the imports
// thus making the plugin useless. This import should happen before we create the singleSPA layout
// and register applications
const importMapOverridesService = new ImportMapOverridesService(
	mfeContextData?.requestContext,
	ldClientSideData?.featureFlagState
);
if (importMapOverridesService.isEnabled()) {
	// dynamically import the node package
	import('import-map-overrides');
	document.body.appendChild(importMapOverridesService.buildUITag());
}

// rum (Real-time User Monitoring)
// We have some limitations for what unauthenticated metrics we accept
// https://github.com/sailpoint/ums/blob/master/src/webui-metric/webui-metric.model.ts#L72
// But splitting it up lets you get metrics working authenticated without doing a ums deploy
const rum = new LoadTimeMetricsService({
	appShellService,
	startTime,
	apiUrl
});

window.addEventListener('single-spa:before-app-change', (evt: CustomEvent) => {
	const { originalEvent, appsByNewStatus } = evt.detail;
	const mountedApps = appsByNewStatus.MOUNTED;

	if (rum) {
		// The absence of 'originalEvent' tells this is an initial load
		if (!originalEvent) {
			rum.trackApplicationStart(startTime);
		} else {
			// clean app monitoring between routing events (not initial load)
			rum.clearApps();
		}
		// always monitor MFEs
		mountedApps.forEach(appName => {
			rum.monitorApp(appName);
			rum.observeAppChangeStart(appName);
		});
	}
});

// A single-spa:routing-event event is fired every time that a routing event has occurred,
// which is after each hashchange, popstate, or triggerAppChange,
// even if no changes to registered applications were necessary;
// and after single-spa verified that all apps were correctly loaded, bootstrapped, mounted, and unmounted.
window.addEventListener('single-spa:routing-event', (evt: CustomEvent) => {
	const mountedApps = evt.detail.appsByNewStatus.MOUNTED;
	const { originalEvent } = evt.detail;

	if (rum) {
		mountedApps.forEach(appName => {
			// the before-app-change event has already set the intialLoad status on
			// which the object will determine what to calculate after the routing is complete
			rum.routingComplete(appName, performance.now());
		});

		if (!originalEvent && mountedApps?.length > 0) {
			rum.trackApplicationEnd(performance.now());
		}
	}
});

// This routing hook is triggered to fire after all single-spa applications have been unmounted,
// but before any new applications have been mounted
window.addEventListener('single-spa:before-mount-routing-event', async (evt: CustomEvent) => {
	const { appsByNewStatus } = evt.detail;

	// Reset navbar when an unmounting process event happens
	if (appsByNewStatus.NOT_MOUNTED.length > 0) {
		(await appShellService.getAppShellStateProvider()).emit({
			topic: Topic.ChangeIsNavbarVisible,
			payload: { isNavbarVisible: true }
		});
	}
});

// Set a listener for the custom event 'locationchange'. SPRenderer will emit and each MFE will subscribe to location changes.
appShellService.getAppShellStateProvider().then(async appShellStateProvider => {
	await appShellStateProvider.set({ currentUrl: window.location.href });

	window.addEventListener('single-spa:routing-event', async (ev: CustomEvent) => {
		const { newUrl } = ev.detail;
		await appShellStateProvider.emit({ topic: Topic.ChangeCurrentUrl, payload: { currentUrl: newUrl } });
	});
});

// Get the configuration data for each MFE to register
const configsElement = document.getElementById('mfe-configs-json');
const mfeConfigs: RegisterConfig[] = contextElement ? (JSON.parse(configsElement.textContent) as RegisterConfig[]) : [];

// single-spa getAppNames make no difference here. Both contain all possible apps even if the route won't mount all of them
// this means that you might get an app that won't matter if you do mfeConfigs[0] or getAppNames(0)

// Initialize the single-spa events that are responsible for storing the load metrics

// Initialize legacy branding stylesheet only for opted-in MFEs
const legacyBranding = new LegacyBrandingService(mfeContextData.requestContext, mfeConfigs);
legacyBranding.appendLegacyBrandingStylesheet();

const appShellPath = document.getElementById('app-shell-path')?.textContent?.trim() || '/';

// This data is set in the app-shell.config and rendered through the app-shell.controller and the template-data.service
const appShellContextElement = document.getElementById('app-shell-context');
const appShellContextData = JSON.parse(appShellContextElement.textContent);
const { appShellNavigationMFE, metaMFEs } = appShellContextData;
appShellContextElement.remove();

// Get the pendo information to run the script and initialize it, this information is organized in
// template-data.service
const pendoDataElement = document.getElementById('pendo-context-json');
const pendoData: PendoContextData = pendoDataElement
	? (JSON.parse(pendoDataElement.textContent) as PendoContextData)
	: null;
pendoDataElement.remove();

// Service to run pendo script and then initialize it with the current information
// eslint-disable-next-line no-new
new PendoService(pendoData, document);

// Construct the layout of MFEs
const appRoutesHtml = buildAppRoutesHtml(mfeConfigs, { navbar: appShellNavigationMFE, metaMFEs });
document.getElementById('single-spa-layout').innerHTML = appRoutesHtml;

// For each of the MFE configurations, register it with single-spa.n
for (const config of mfeConfigs) {
	// Function to find the first configured route for an MFE. Loops the configured routes and
	// compares current location. Returns the first one matched.
	const firstMatchedRoute: (location: Location) => string | null = (location: Location) => {
		for (const route of config.routes) {
			const fullMatchPath = route === '*' ? `/${trimSlashes(appShellPath)}` : `/${joinPath(appShellPath, route)}`;
			if (includePath(location.pathname, fullMatchPath)) {
				return fullMatchPath;
			}
		}
		return null;
	};

	// Function to determine if any of an MFE's configured routes matches the current location.
	const anyRouteMatches: ActivityFn = (location: Location) =>
		!authCredentialsService?.isSessionExpired && !!firstMatchedRoute(location);

	// Create the registration configuration argument, assign MFE context data object as "customProps"
	applications.push({
		name: config.name,
		app: () => System.import(config.specifier),
		activeWhen: anyRouteMatches,
		customProps: () => {
			// Get the config route that was matched to load this MFE.
			const currentRoute = firstMatchedRoute(window.location);

			// Define the information specific to each MFE.
			const mfeInfo: MfeInfo = {
				name: config.name,
				route: currentRoute,
				url: config.url
			};

			const mfeCustomProps: MfeProps = {
				[APP_SHELL_SERVICE_NAME]: appShellService.getProxiedAppshell(mfeInfo),
				[MFE_INFO_NAME]: mfeInfo
			};

			return mfeCustomProps;
		}
	});
}

// Register each of the applications
const layoutRoutes = constructRoutes(document.querySelector('#single-spa-layout').innerHTML);
constructLayoutEngine({ routes: layoutRoutes, applications });
applications.forEach(registerApplication);

const translationService = new TranslationService();
const sessionExpiredPage = createSessionExpiredPage(translationService);

// Register the 'Session Expired' page.
registerApplication({
	name: sessionExpiredPage.pageName,
	app: sessionExpiredPage,
	activeWhen: () => authCredentialsService?.isSessionExpired,
	customProps: {
		logoutUrl: mfeContextData.authContext.logoutUrl,
		languagePackage: mfeContextData.requestContext.languagePackage
	}
});

/**
 * Start single-spa listening to routes.
 */
start();
