/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { LayoutElement, RegisterConfig } from './mfe-register.model';

/*
	single-spa-layout

	Please refer the the documentation for layout API and details
	https://single-spa.js.org/docs/layout-definition
*/
export const buildAppRoutesHtml = (mfeConfigs: RegisterConfig[], { navbar, metaMFEs }: LayoutElement = {}) => {
	let htmlString = '<single-spa-router>';
	htmlString += '<main id="mfe-wrapper">';
	htmlString += '<nav class="mfe-topnav">';
	if (navbar) {
		const config = mfeConfigs.find(mfeConfig => mfeConfig.specifier === navbar);
		htmlString += `<application name=${config.specifier}></application>`;
	}
	htmlString += '</nav>';

	const metaMFESpecifiers = metaMFEs ? metaMFEs.map(({ mfeName }) => mfeName) : [];
	// "body" MFEs
	htmlString += '<div class="mfe-maincontent">';
	mfeConfigs
		.filter(config => config.specifier !== navbar && !metaMFESpecifiers.includes(config.specifier))
		.forEach(config => {
			htmlString += `<application name=${config.specifier}></application>`;
		});
	htmlString += '</div>';
	// meta MFEs
	mfeConfigs
		.filter(config => config.specifier !== navbar && metaMFESpecifiers.includes(config.specifier))
		.forEach(config => {
			htmlString += `<application name=${config.specifier}></application>`;
		});
	htmlString += '</main>';
	htmlString += '</single-spa-router>';
	return htmlString;
};
