/*
 * Copyright (C) 2022 SailPoint Technologies, Inc.  All rights reserved.
 */

/**
 * Utility functions for building URL paths
 */

/**
 * Utility function to trim leading an trailing slashes. Used to compose valid paths delimited by single slashes.
 * @param str string
 * @returns string
 */
export function trimSlashes(str: string) {
	if (str != null && str.length) {
		while (str.length && str[str.length - 1] === '/') {
			str = str.slice(0, str.length - 1);
		}
		while (str.length && str[0] === '/') {
			str = str.slice(1, str.length);
		}
	}
	return str || '';
}

/**
 * Compose a valid path delimited by single slashes.
 * @param p string - 1 to n strings to combine
 * @returns string - single string composed of arguments joined by slashes
 */
export function joinPath(...p: string[]) {
	return trimSlashes(
		p
			.map(trimSlashes)
			.filter(x => x)
			.join('/')
	);
}

/**
 * Clean and decode string from '+'s because the javascript library doesn't convert them to spaces
 * @param {string} str string to decode
 * @returns string
 */
export function urlDecode(str: string) {
	return decodeURIComponent(str).replace(/\+/g, ' ');
}

/**
 * Validates if one path contains a specific route
 * @param {string} path value used as based path, in order to search for the given path
 * @param {string} searchPath we going to search this value within the base path
 * @returns boolean
 */
export function includePath(path: string, searchPath: string) {
	return encodeURIComponent(urlDecode(path)).indexOf(encodeURIComponent(urlDecode(searchPath))) === 0;
}
