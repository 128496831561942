/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { TranslationService } from '../translation.service';
import commonHeader from './common/header.html';
import commonStyles from './common/styles.html';
import { LifeCycles } from 'single-spa';
import singleSpaHtml from 'single-spa-html';

export interface MfeStaticPage extends LifeCycles {
	/**
	 * The name of the static page.
	 */
	pageName: string;
}

export interface StaticPageOptions {
	/**
	 * The name of the static page.
	 */
	pageName: string;

	/**
	 * An HTML string that will be injected into the DOM during the single-spa mount lifecycle.
	 */
	template: string;

	/**
	 * (Optional) A function to be called after the application has been mounted.
	 * @param opts
	 * @param props
	 */
	onMount?: (opts, props) => void;

	/**
	 * Name of file with language translations that should be loaded
	 */
	languagePackage?: string;
}

/**
 * Creates a static page that can be mounted as a single-spa application.
 * @param options Static page options.
 * @param translationService Object with dictionary for translations
 * @returns A single-spa application generated from a static page.
 */
export function createStaticPage(options: StaticPageOptions, translationService: TranslationService): MfeStaticPage {
	const pageTemplate = `
		${commonStyles}
		${commonHeader}
		${options.template}
	`;

	const staticPage = singleSpaHtml({ template: translationService.applyTranslations(pageTemplate) });
	staticPage.pageName = options.pageName;

	if (typeof options.onMount === 'function') {
		const originalMount = staticPage.mount;
		staticPage.mount = (opts, props) =>
			originalMount(opts, props)
				.then(() => {
					// if it's required to load file with language package
					if (options.languagePackage) {
						return translationService.load(options.languagePackage, opts.languagePackage);
					}
				})
				.then(() => options.onMount(opts, props));
	}

	return staticPage;
}
