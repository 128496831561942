export interface AppShellState {
	currentUrl: string;
	isNavbarVisible: boolean;
	heading: string;
	testNumber?: number;
}

export enum Topic {
	ChangeIsNavbarVisible = 'ChangeIsNavbarVisible',
	ChangeHeading = 'ChangeHeading',
	ChangeTestNumber = 'ChangeTestNumber',
	ChangeCurrentUrl = 'ChangeCurrentUrl'
}

export type Message =
	| { topic: Topic.ChangeIsNavbarVisible; payload: { isNavbarVisible: boolean } }
	| { topic: Topic.ChangeHeading; payload: { heading: string } }
	| { topic: Topic.ChangeTestNumber; payload: { testNumber: number } }
	| { topic: Topic.ChangeCurrentUrl; payload: { currentUrl: string } };

export type MessagePayload = Message['payload'];

export type OnMessageFn = (payload: MessagePayload) => void;
/**
 * Describes the MFE State Manager
 * This manager also acts as an EventBus (pub/sub) provider
 */
export interface AppShellStateProvider {
	emit: (message: Message) => Promise<void>;
	on: ({ topic, onMessage }: { topic: Topic; onMessage: OnMessageFn }) => Promise<Readonly<string>>;
	set: (newState: {
		[key in keyof AppShellState]?: AppShellState[key];
	}) => void;
	get: () => Promise<{ [key in keyof AppShellState]?: AppShellState[key] }>;
	off: ({ subscriptionID, topic }: { subscriptionID: string; topic: Topic }) => Promise<void>;
}
